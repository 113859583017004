import dayjs from 'dayjs';
import { reads } from 'macro-decorators';

import {
  getEnabledLegalCountries,
  REGISTRATION_DATE_THRESHOLD_MONTHS,
} from 'qonto/constants/insurance-hub';

import ApplicationAbility from './application';

export default class InsuranceAbility extends ApplicationAbility {
  @reads('organizationManager.organization') organization;

  get canRead() {
    return (
      this.permissions.insurance_contracts.read &&
      getEnabledLegalCountries().includes(this.organizationManager.organization.legalCountry)
    );
  }

  get canNavigate() {
    return this.canRead && this.isGermanOrganizationNotOlderThan36Months();
  }

  isGermanOrganizationNotOlderThan36Months() {
    return (
      !this.organization.isGerman ||
      dayjs(this.organization.legalRegistrationDate).isAfter(
        dayjs().subtract(REGISTRATION_DATE_THRESHOLD_MONTHS, 'month')
      )
    );
  }
}
